import axios from 'axios'
import authHeader from '../helper/authHeader'

class Service {
  baseUrl = `${process.env.VUE_APP_API_URL}api/`
  constructor(urlArgs = [], version = 'v1') {
    this.url = `${version}/${this.generateURL(urlArgs)}`
  }

  generateURL(urlArgs) {
    let generatedUrl = ''

    for (let i = 0; i < urlArgs.length; i++) {
      const urlArg = urlArgs[i]
      generatedUrl += urlArg
      if (i !== urlArgs.length - 1) {
        generatedUrl += '/'
      }
    }

    return generatedUrl
  }

  get(queries = {}, eUrl) {
    let newUrl = this.baseUrl + this.url
    if (eUrl) newUrl += eUrl

    let queryUrl = '?'
    for (const query in queries) {
      const value = queries[query]
      queryUrl += `${query}=${value}&`
    }

    return axios({
      method: 'GET',
      headers: {
        Authorization: authHeader()
      },
      url: newUrl + queryUrl
    })
  }

  post(data, eUrl) {
    let newUrl = this.baseUrl + this.url
    if (eUrl) newUrl += eUrl
    return axios({
      method: 'POST',
      headers: {
        Authorization: authHeader()
      },
      url: newUrl,
      data: data
    })
  }

  put(data, eUrl) {
    let newUrl = this.baseUrl + this.url
    if (eUrl) newUrl += eUrl
    return axios({
      method: 'PUT',
      headers: {
        Authorization: authHeader()
      },
      url: newUrl,
      data: data
    })
  }

  del(eUrl) {
    let newUrl = this.baseUrl + this.url
    if (eUrl) newUrl += eUrl
    return axios({
      method: 'DELETE',
      headers: {
        Authorization: authHeader()
      },
      url: newUrl
    })
  }

  delMultiple(data) {
    return axios({
      method: 'DELETE',
      headers: {
        Authorization: authHeader()
      },
      url: this.baseUrl + this.url,
      data: data
    })
  }

  upload(data, eUrl) {
    let newUrl = this.baseUrl + this.url
    if (eUrl) newUrl += eUrl
    return axios({
      method: 'POST',
      headers: {
        Authorization: authHeader(),
        'Content-Type': 'multipart/form-data'
      },
      url: newUrl,
      data: data
    })
  }

  async create(data) {
    return this.post(data)
  }

  async getById(id) {
    return this.get(id)
  }

  async update(data) {
    return this.put(data)
  }

  async list(page, field = null, filter = null, sort = null) {
    if (!page) page = { size: 20, number: 1 }

    const queryUrl = {
      'page[size]': page.size,
      'page[number]': page.number
    }

    if (field) {
      for (const key in field) {
        queryUrl[`fields[${key}]`] = field[key]
      }
    }

    if (filter) {
      for (const key in filter) {
        let query = `filter[${key}]`

        for (const key2 in filter[key]) {
          query += `[${key2}]`
          queryUrl[query] = filter[key][key2]
        }
      }
    }

    return this.get(queryUrl)
  }
}

export default Service
